.banner-container{
    padding: 80px 0 30px;
    .image-gallery-slide img {
        width: 100%;
        display: block;
      }
      .image-gallery-thumbnail-image{
        display: block;
    }
    img {
      margin: 0 auto;
      height: auto;
    }

    @media only screen and (max-width: 1100px) {
        padding: 0 0 30px;
      }

      @media only screen and (max-width: 650px) {        
        .image-gallery-content .image-gallery-slide .image-gallery-image{
            max-height: calc(100vh - 400px);
            height: 180px;
        }
        .image-gallery-slide .image-gallery-image{
            object-fit: cover;
        }
      }
}